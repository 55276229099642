import React from 'react'
import CorForm from '../../../../forms/cadastro/cor/CorForm'
import { getCorDTO } from '../../../../models/cadastro/veiculo/CorModel'
import CorService from '../../../../services/cadastro/veiculo/CorService'
import { Crud } from '../../../../classes/NewCrud'
import { CorValidator } from './CorValidator'


export default function Cor (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={CorForm}
      valida={CorValidator}
      modelGetDTO={getCorDTO}
      service={CorService}
      resource="cor"
      primarykeyname="codigo_cor"
      formTitle="Cor"
    />
  )
}