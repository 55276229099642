import moment from 'moment'
import { formatDateToAmerican, formatPlacha } from '../../../helpers/formaters'

export const getFormaPagamentoDTO = (data = {}) => {
  return {
    codigo_fpn: data.codigo_fpn,
    bancos: data.bancos || [],
    banco: data.banco || [],
    codfin_fpn: data.codfin_fpn || null,
    contas: data.contas || [],
    contasBancarias: data.contasBancarias || [],
    cota: data.cotcon_fpn || 0,
    cpfCnpjCedente: data.cpfced_fpn || '',
    dataCompensacao: data.datcom_fpn ? formatDateToAmerican(data.datcom_fpn) : new Date(),
    dataEmissao: data.data_fpn ? moment(data.data_fpn).toDate() : new Date(),
    descricaoBanco: '',
    digitoVerificador1: data.d1_fpn || '',
    digitoVerificador2: data.d2_fpn || '',
    digitoVerificador3: data.d3_fpn || '',
    finabe_fpn: data.finabe_fpn || false,
    financeira: { codigo_fin: null },
    floorPlan: data.perflp_fpn || 0,
    formaCobranca: {},
    grupo: data.grucon_fpn || 0,
    ilaPercentagem: data.perila_fpn || 0,
    ilaValor: data.valila_fpn || 0,
    irrfPercentagem: data.perirrf_fpn || 0,
    irrfValor: data.valirrf_fpn || 0,
    isLojaPaga: data.revpag_fpn || false,
    revpag_fpn: data.revpag_fpn || false,
    isPix: data.ispix_fpn || false,
    isSinal: data.sinal_fpn || false,
    jurcredvei_fpn: data.jurcredvei_fpn || 0,
    jurcreddoc_fpn: data.jurcreddoc_fpn || 0,
    nomeCedente: data.nomced_fpn || '',
    numeroAgencia: data.numage_fpn || '',
    numeroBanco: data.numban_fpn || '',
    numeroCheque: data.numche_fpn || '',
    numeroConta: data.conta_fpn || '',
    observacoes: data.obs_fpn || '',
    pagace_fpn: data.pagace_fpn || 0,
    prazoEntreParcelas: data.prapar_fpn || 0,
    primeiroVencimento: data.priven_fin ? moment(data.priven_fin).toDate() : null,
    quantidadeParcelas: data.qtdpar_fpn || 0,
    restricao: { codigo_trv: null },
    retorno: data.retorn_fpn || data?.retornoOptions[0],
    rettac_fpn: data.rettac_fpn || false,
    tipoFinanciamento: data.codtpf_fpn || null,
    titular: data.nomtit_fpn || '',
    trocot_fpn: data.trocot_fpn || 0,
    valor: data.valor_fpn || 0,
    valorVeiculo: data.pagvei_fpn || 0,
    valorDocumento: data.pagdoc_fpn || 0,
    valorAcordo: data.valaco_fpn || 0,
    valorComplemento: data.valcom_fpn || 0,
    valorParcela: data.valpar_fpn || 0,
    valorRetorno: data.valret_fpn || 0,
    valorRetornoLiquido: data.valretliq_fpn || 0,
    valorTAC: data.valtac_fpn || 0,
    veiculo: {
      ...data?.veiculo,
      placha: data.veiculo ? formatPlacha(data.veiculo?.placa_vei, data.veiculo?.chassi_vei) : ''
    } || {},
    veiculoTroca: data.veiculoTroca || {},
    veitro_fpn: data.veitro_fpn || data.veiculoTroca?.codigo_vei || null,
    validacao: {},
    retornoOptions: data.retornoOptions || [],
    autpag_fpn: data.autpag_fpn || false
  }
}

export const postFormaPagamentoDTO = (data) => {
  return {
    banco: data.banco,
    codfin_fpn: data.financeira?.codigo_fin,
    codfoc_fpn: data.formaCobranca?.codigo_foc,
    codtpf_fpn: data.tipoFinanciamento,
    codtrv_fpn: data.restricao?.codigo_trv,
    codvei_fpn: data.veiculo?.codigo_vei,
    conta_fpn: data.numeroConta,
    cotcon_fpn: data.cota,
    cpfced_fpn: data.cpfCnpjCedente,
    d1_fpn: data.digitoVerificador1,
    d2_fpn: data.digitoVerificador2,
    d3_fpn: data.digitoVerificador3,
    data_fpn: moment(data.dataEmissao).format('YYYY-MM-DD') === 'Invalid date'
      ? null
      : moment(data.dataEmissao).format('YYYY-MM-DD'),
    datcom_fpn: moment(data.dataCompensacao).format('YYYY-MM-DD') === 'Invalid date'
      ? null
      : moment(data.dataCompensacao).format('YYYY-MM-DD'),
    finabe_fpn: data.finabe_fpn || 0,
    grucon_fpn: data.grupo,
    ispix_fpn: data.isPix,
    jurcredvei_fpn: data.jurcredvei_fpn || 0,
    jurcreddoc_fpn: data.jurcreddoc_fpn || 0,
    nomced_fpn: data.nomeCedente,
    nomtit_fpn: data.titular?.trim(),
    numage_fpn: data.numeroAgencia,
    numban_fpn: data.numeroBanco,
    numche_fpn: data.numeroCheque,
    obs_fpn: data.observacoes,
    pagace_fpn: data.pagace_fpn || 0,
    pagvei_fpn: data.valorVeiculo,
    pagdoc_fpn: data.valorDocumento,
    perflp_fpn: data.floorPlan || 0,
    perila_fpn: data.ilaPercentagem,
    perirrf_fpn: data.irrfPercentagem,
    prapar_fpn: data.prazoEntreParcelas || '',
    priven_fin: moment(data.primeiroVencimento).format('YYYY-MM-DD') === 'Invalid date'
      ? null
      : moment(data.primeiroVencimento).format('YYYY-MM-DD'),
    qtdpar_fpn: data.quantidadeParcelas || 0,
    retorn_fpn: data.retorno?.valor || 0,
    rettac_fpn: data.rettac_fpn || 0,
    revpag_fpn: data.isLojaPaga || false,
    sinal_fpn: data.isSinal,
    trocot_fpn: data.trocot_fpn || 0,
    valaco_fpn: data.valorAcordo,
    valcom_fpn: data.valorComplemento,
    valila_fpn: data.ilaValor,
    valirrf_fpn: data.irrfValor,
    valor_fpn: data.valor,
    valpar_fpn: data.valorParcela,
    valret_fpn: data.valorRetorno,
    valretliq_fpn: data.valorRetornoLiquido,
    valtac_fpn: data.valorTAC,
    veitro_fpn: data.veitro_fpn,
    veiculoTroca: data.veiculoTroca,
    autpag_fpn: data.autpag_fpn
  }
}
