import ServiceBase from '../../../base/ServiceBase'

export default class PixGeradoService {
  static async gerarPix (dadosPix) {
    return await ServiceBase.postBase('financeiro/integracao/gerarPIX', dadosPix)
  }

  static async cancelarPix (codigoNegociacaoVeiculos) {
    return await ServiceBase.deleteBase(`financeiro/integracao/pix-gerado/${codigoNegociacaoVeiculos}`)
  }

  static async listarPix (emp, nve) {
    return await ServiceBase.getBase(`financeiro/integracao/getDadosPixByNve/${emp}/${nve}`)
  }

  static async getPixByNveESituacao (codNve, codSit) {
    const params = { codNve, codSit }
    const queryString = new URLSearchParams(params).toString()
    return await ServiceBase.getBase(`financeiro/integracao/getDadosPixByNveESituacao?${queryString}`)
  }
}
