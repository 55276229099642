import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React, { useState } from 'react'
import DataList from '../../../../../components/layout/DataList'
import { showWarnMessage } from '../../../../../components/utils/Message'
import ResponsiveColumn from '../../../../../components/utils/ResponsiveColumn'
import { postRepresentanteDTO } from '../../../../../dtos/cadastro/pessoa/RepresentanteDTO'
import ModalPessoaRepresentante from './ModalPessoaRepresentante'

const DadosRepresentantes = ({
  form,
  edicaoHabilitada,
  camposObrigatorios
}) => {
  // * Estados para controle do modal e do representante selecionado para edição ou inserção.
  const [visibleModalPessoaRepresentante, setVisibleModalPessoaRepresentante] = useState(false)
  const [representanteSelecionado, setRepresentanteSelecionado] = useState(null)
  const [edicao, setEdicao] = useState(null)

  // * Add representante se não esta na listagem
  function adicionarRepresentante () {
    const representantes = form.values.representantes
    const jaAdicionado = representantes.some(
      representante => representante.codigo_pes === representanteSelecionado.codigo_pes
    )

    // * Insere
    if (!jaAdicionado) {
      representantes.push(postRepresentanteDTO(representanteSelecionado))
    } else {
      showWarnMessage('Já existe esse representante adicionado!')
    }
  }

  // * Altera representante baseado no estado edicao
  function editarRepresentante () {
    const representantes = form.values.representantes

    // * Busca o indice no array do objeto que está sendo editado
    // * baseado no codigo_pes
    // * que é setado ao abrir o modal pela listagem!
    const indexRepresentanteSelecionado = representantes.findIndex(
      (valor) => valor.codigo_pes === edicao.codigo_pes
    )

    // * Edita
    if (indexRepresentanteSelecionado !== -1) {
      representantes.splice(
        indexRepresentanteSelecionado,
        1,
        postRepresentanteDTO(representanteSelecionado)
      )
    } else {
      showWarnMessage('Erro ao atualizar representante!')
    }
  }

  // * Insere ou altera representando baseado se está na listagem ou não
  function insertOrUpdateRepresentante () {
    !!edicao
      ? editarRepresentante()
      : adicionarRepresentante()

    // * Sempre reseta valores após edição ou inserção
    handleCloseModal()
  }

  // * Abre modal editar setando valores
  function handleOpenModalEditar (e) {
    setEdicao(e?.data)
    setRepresentanteSelecionado(e?.data)
    setVisibleModalPessoaRepresentante(true)
  }

  // * Fecha modal resetando valores
  function handleCloseModal () {
    setVisibleModalPessoaRepresentante(false)
    setRepresentanteSelecionado(null)
    setEdicao(null)
  }

  return (
    <div className="formgrid grid fluid">

      {/* LINHA DO BOTÃO ADICIONAR */}
      <div className='w-full md:flex justify-content-end'>
        <div className="field col-12 md:col-4 flex-column justify-content-end mg:m-8">
          <div className='flex sm:w-full justify-content-end'>
            <Button
              label="Adicionar representante"
              className=' mr-2'
              icon="pi pi-plus"
              onClick={() => setVisibleModalPessoaRepresentante(true)}
              disabled={!edicaoHabilitada}
            />
          </div>
        </div>
      </div>

      {/* LISTAGEM */}
      <div className="field col-12">
        <DataList
          data={form.values.representantes}
          rows={form.values.representantes.length}
          totalRecords={form.values.representantes.length}

          page={1}
          first={1}
          responsive={true}
          showFilter={false}
          hidePaginator={true}

          selected={form.values.representantes?.find(rep => rep.codigo_pes === edicao?.codigo_pes)}
          onRowSelect={handleOpenModalEditar}
          // * Tem que passar essa prop sem nada pra poder usar a de cima
          onSelect={() => {}}
        >
          <Column
            header="Código"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Código"
                value={data.codigo_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="codigo_pes"
          />
          <Column
            header="Representantes"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Representantes"
                value={data.nomraz_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="nomraz_pes"
          />
        </DataList>

        {/* MODAL PARA ADD / EDITAR / EXCLUIR REPRESENTANTE */}
        <ModalPessoaRepresentante
          camposObrigatorios={camposObrigatorios}
          visible={visibleModalPessoaRepresentante}
          onHide={handleCloseModal}
          edicaoHabilitada={edicaoHabilitada}
          edicao={edicao}
          insertOrUpdate={insertOrUpdateRepresentante}
          form={form}
          selected={representanteSelecionado}
          setSelected={setRepresentanteSelecionado}
        />
      </div>

    </div>
  )
}

export default DadosRepresentantes
