import { server } from '../../helpers/server'

export default class ServiceBase {
  static async getBase (request, loading = true, type = 'json') {
    try {
      const response = await server(loading, type).get(request)
      return response.data?.value || response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async postBase (url, data, loading = true, config = null) {
    try {
      let response

      if (config)
        response = await server(loading).post(url, data, config)
      else
        response = await server(loading).post(url, data)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async putBase (url, data, loading = true, config = null) {
    try {
      let response

      if (config)
        response = await server(loading).put(url, data, config)
      else
        response = await server(loading).put(url, data)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async deleteBase (request, loading = true, config = null) {
    try {
      let response

      if (config)
        response = await server(loading).delete(request, config)
      else
        response = await server(loading).delete(request)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }
}
