import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import React, { Component } from 'react'
import CommonHelper from '../../helpers/CommonHelper'

class DataList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      collapsed: this.props.collapsed || true,
      expandedFilters: false
    }
  }

  render () {
    const props = this.props
    const rows = props.data?.length
    const ExpandedFiltersTemplate = props.expandedFiltersTemplate

    return (
      <>
        {props.showFilter && (
          <div className="table-header grid formgrid">
            <div className="field col-12">
              {props.headerLabel &&
              <div className="flex justify-content-between align-items-center page-header">
                <h6 className='pl-2'>{props.headerLabel}</h6>
              </div>
              }
              {!props.notSearch &&
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" style={{ color: '#8a9099' }} />
              <InputText
                placeholder={props.filterPlaceholder}
                className="inputfield w-full"
                name={props.filterName}
                onChange={props.filterOnChange}
                value={props.filterValue}
                onKeyDown={(e) => { if (e.key === 'Enter') props.onFilter() }} />
              {props.expandedFiltersTemplate && (
                <i
                  className="pi pi-sliders-h cursor-pointer"
                  style={{ color: '#8a9099', right: '18px' }}
                  onClick={() => this.setState({ expandedFilters: !this.state.expandedFilters })} />
              )}
            </span>}
            </div>
            {this.state.expandedFilters && (
              <ExpandedFiltersTemplate />
            )}
          </div>
        )}
        {(props.onSelect !== undefined || props.onSelectionChange !== undefined) && (
          <DataTable
            headerColumnGroup={props.headerColumnGroup}
            resizableColumns
            className={'datalist p-datatable-responsive ' + props.className}
            header={props.header}
            onSelectionChange={props.onSelectionChange || (event => props.onSelect(event.value))}
            onRowDoubleClick={props.onRowDoubleClick}
            onRowSelect={props.onRowSelect}
            onRowClick={props.onRowClick}
            paginatorPosition="bottom"
            responsive={props.responsive}
            selection={props.selected}
            selectionMode={props.selectionMode || 'single'}
            style={{ marginTop: '15px', minWidth: '300px' }}
            value={props.data}
            expandedRows={props.expandedRows}
            rowExpansionTemplate={props.rowExpansionTemplate}
            dataKey={props.dataKey}
            emptyMessage="Nenhum registro foi encontrado"
            scrollable={props.scrollable}
            scrollDirection={props.scrollDirection}
            scrollHeight={props.scrollHeight}
            rowGroupMode={props.rowGroupMode}
            groupField={props.groupField}
            frozenWidth={props.frozenWidth}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            dragSelection={props.dragSelection}
            metaKeySelection={props.metaKeySelection || false}
          >
            {props.children}
          </DataTable>
        )}
        {(props.onSelect === undefined && props.onSelectionChange === undefined) && (
          <DataTable
            className={'datalist p-datatable-responsive ' + props.className}
            header={props.actions}
            paginatorPosition="bottom"
            responsive={props.responsive}
            style={{ marginTop: '15px', minWidth: '300px' }}
            value={props.data}
            expandedRows={props.expandedRows}
            rowExpansionTemplate={props.rowExpansionTemplate}
            dataKey={props.dataKey}
            emptyMessage="Nenhum registro foi encontrado"
            scrollable={props.scrollable}
            scrollDirection={props.scrollDirection}
            scrollHeight={props.scrollHeight}
            rowGroupMode={props.rowGroupMode}
            groupField={props.groupField}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            rowClassName={props.rowClassName}
            reorderableColumns={props.reorderableColumns}
            removableSort={props.removableSort}
            resizableColumns={props.resizableColumns}
            sortField={props.sortField}
            sortOrder={props.sortOrder}
            onSort={props.onSort}
          >
            {props.children}
          </DataTable>
        )}
        {!props.hidePaginator && (
          <div className="table-footer">
            <div className="table-rows">
              <span className="table-rows-quantity">
                Exibindo {props.totalRecords === 0 ? 0 : ((props.rows * (props.page - 1)) + 1)}-{props.rows * (props.page - 1) + rows} de {props.totalRecords}
              </span>
            </div>
            <Paginator
              className="paginator"
              style={{ marginTop: '10px' }}
              first={props.first}
              rows={props.rows}
              totalRecords={props.totalRecords}
              onPageChange={props.onPage}
              pageLinkSize={CommonHelper.isDesktop() ? 5 : 2}
            ></Paginator>
          </div>
        )}
      </>
    )
  }
}

export default DataList
