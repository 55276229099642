import { classNames } from 'primereact/utils'
import React, { Component } from 'react'
import { VscQuestion } from 'react-icons/vsc'
import ReactTooltip from 'react-tooltip'
import { useValidateInput } from '../../../helpers/useValidateInput'
import AppButton from '../../layout/AppButton'
import FieldErrorMessage from '../../utils/FieldErrorMessage'
import FiltroModal from '../../utils/FiltroModal'
import RegisterModal from '../../utils/RegisterModal'
import RequiredLabel from '../../utils/RequiredLabel'
import './_inputBase.scss'

class InputBase extends Component {
  constructor (props) {
    super(props)

    this.state = {
      visibleFiltroModal: props.visibleFiltroModal,
      setVisibleFiltroModal: props.setVisibleFiltroModal,
      loading: true
    }

    this.onBlur = this.onBlur.bind(this)
    this.getInputClassName = this.getInputClassName.bind(this)
    this.isFormFieldValid = this.isFormFieldValid.bind(this)
    this.getFormErrorMessage = this.getFormErrorMessage.bind(this)
  }

  setLoading (bool) {
    this.setState({ loading: bool })
  }

  componentDidMount () {
    const { props } = this

    if (!props.form) {
      this.setLoading(false)
    }

    if (props.camposObrigatorios?.length) {
      this.isCampoObrigatorio = props.camposObrigatorios && props.camposObrigatorios.includes(props.name)
      this.setLoading(false)
    }
    if (props.abriModalAtalho) {
      window.addEventListener('keydown', (e) => this.abrirFiltroModal(e))
      return () => {
        window.removeEventListener('keydown', (e) => this.abrirFiltroModal(e))
      }
    }
  }

  abrirFiltroModal (event) {
    if (event.altKey && event.key === 'a') {
      this.setState({ visibleFiltroModal: true })
    }
  }

  componentDidUpdate (prevProps) {
    const { props } = this

    if (prevProps.camposObrigatorios?.length !== props.camposObrigatorios?.length) {
      this.isCampoObrigatorio = props.camposObrigatorios && props.camposObrigatorios.includes(props.name)
      this.setLoading(false)
    }
  }

  isFormFieldValid () {
    const { props } = this

    const { isFormFieldValid } = useValidateInput(props.form)

    return isFormFieldValid(props.name)
  }

  getFormErrorMessage () {
    const { props } = this

    const { getFormErrorMessage } = useValidateInput(props.form)

    return getFormErrorMessage(props.name)
  }

  onBlur (event) {
    const { props } = this

    if (props.onBlur) {
      props.onBlur(event)
    }

    if (this.isCampoObrigatorio && props.form) {
      return props.form.setFieldTouched(event.target.name)
    }

    return
  }

  onHide () {
    const { props } = this

    if (props.onHide) {
      props.onHide()
    }

    if (this.isCampoObrigatorio && props.form) {
      return props.form.setFieldTouched(props.name)
    }

    return
  }

  getInputClassName () {
    const { props } = this

    const defaultClassName = 'inputfield w-full ' + props.className || ''

    if (this.isCampoObrigatorio && props.form) {
      return classNames({ 'p-invalid': this.isFormFieldValid() }, defaultClassName)
    }

    return defaultClassName
  }

  renderInputOpcional () {
    return (
      <>
        {this.getInputLabel()}
        {this.getInputContent()}
      </>
    )
  }

  renderInputObrigatorio () {
    return (
      <>
        {this.getInputLabel()}
        {this.getInputContent()}
        <FieldErrorMessage message={this.getFormErrorMessage()}/>
      </>
    )
  }

  getInputContent () {
    const { props } = this
    if (props.filtrotitle) {
      return (
        <>
          <div className="p-inputgroup">
            {this.renderInput()}
            <AppButton
              type="button"
              icon="pi pi-search"
              onClick={() => {
                if (!!this.state.setVisibleFiltroModal) this.state.setVisibleFiltroModal(true)
                this.setState({ visibleFiltroModal: true })
              }}
              disabled={props.disabled && !props.searchAlwaysEnabled}
              onBlur={props.searchOnBlur}
            />
          </div>
          <FiltroModal
            semFiltroInicial={props.semFiltroInicial}
            title={props.filtrotitle}
            visible={this.state.visibleFiltroModal}
            hide={() => {
              if (!!this.state.setVisibleFiltroModal) this.state.setVisibleFiltroModal(false)
              this.setState({ visibleFiltroModal: false })
            }}
            service={props.service}
            model={props.model}
            primarykeyname={props.primarykeyname}
            columns={props.columns}
            onSelect={props.onSelect}
            filtersDefault={props.filtersDefault}
            expandedFiltersTemplate={props.expandedFiltersTemplate}
            horizontalScroll={props.horizontalScroll}
            width={props.width}
            full={props.full}
            hidePaginator={props.hidePaginator}
            perPage={props.perPage}
          />
        </>
      )
    }

    return this.renderInput()
  }

  getInputLabel () {
    const { props } = this

    const labelComponent = this.getLabelComponent()

    return (
      <>
        <div className="flex align-items-center">
          {labelComponent}
          {props.inputTooltip && (
            <>
              <VscQuestion className="input-tooltip-icon" data-tip={props.inputTooltip} {...props.inputTooltipOptions}/>
              <ReactTooltip effect="solid" />
            </>
          )}
        </div>
      </>
    )
  }

  getLabelComponent () {
    const { props } = this

    let labelComponent = <label htmlFor={props.name}>{props.label}</label>

    if (this.isCampoObrigatorio) {
      labelComponent = <RequiredLabel label={props.label} valid={this.isFormFieldValid()} />
    }

    if (props.ComponenteCadastro) {
      labelComponent = (
        <RegisterModal
          isCampoObrigatorio={this.isCampoObrigatorio}
          ComponenteCadastro={props.ComponenteCadastro}
          isFormFieldValid={this.isFormFieldValid()}
          primarykeyname={props.primarykeyname}
          onSaveModal={props.onSaveModal}
          onDeleteModal={props.onDeleteModal}
          modalHeader={props.modalHeader}
          selected={props.selected}
          label={props.label}
        />
      )
    }

    return labelComponent
  }

  renderInput () {
    return <></>
  }

  render () {
    if (this.state.loading) {
      return <></>
    }

    return this.isCampoObrigatorio ? this.renderInputObrigatorio() : this.renderInputOpcional()
  }
}

export default InputBase
