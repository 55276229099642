import moment from 'moment'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDateToAmerican } from '../../../helpers/formaters'

export const getPessoaFisicaDTO = (data = {}) => {
  if (data?.pessoaf) {
    data.pessoaf.datnas_pef = new Date(moment(data?.pessoaf?.datnas_pef).local())
  }

  data.representantes = data.representantes || []
  data.representantes = data.representantes.map(representante => ({
    codigo_rep: representante.codigo_rep,
    codigo_pes: representante.representante.codigo_pes,
    nomraz_pes: representante.representante.nomraz_pes
  }))

  return {
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null,
    apelid_pes: data.apelid_pes || null,
    end_pes: data.end_pes || null,
    bairro_pes: data.bairro_pes || null,
    cidade_pes: data.cidade_pes || null,
    uf_pes: data.uf_pes || null,
    pais_pes: data.pais_pes || null,
    cep_pes: data.cep_pes || null,
    email_pes: data.email_pes || null,
    emafin_pes: data.emafin_pes || null,
    numend_pes: data.numend_pes || null,
    codemp_pes: data.codemp_pes || null,
    cgccpf_pes: data.cgccpf_pes || null,
    idint_pes: data.idint_pes || null,
    ierg_pes: data.ierg_pes || null,
    ie_pes: data.ie_pes || null,
    rg_pes: data.rg_pes || null,
    telcel_pes: data.telcel_pes || null,
    telcom_pes: data.telcom_pes || null,
    telres_pes: data.telres_pes || null,
    insmun_pes: data.insmun_pes || null,
    comend_pes: data.comend_pes || null,
    codmun_pes: data.codmun_pes || null,
    codpai_pes: data.codpai_pes || null,
    usuins_pes: data.usuins_pes || null,
    refban_pes: data.refban_pes || '',
    datins_pes: data.datins_pes || null,
    dahalt_pes: data.dahalt_pes || null,
    numcom_pes: data.numcom_pes || null,
    cepcom_pes: data.cepcom_pes || null,
    endcom_pes: data.endcom_pes || null,
    comcom_pes: data.comcom_pes || null,
    muncom_pes: data.muncom_pes || null,
    barcom_pes: data.barcom_pes || null,
    numcob_pes: data.numcob_pes || null,
    cepcob_pes: data.cepcob_pes || null,
    endcob_pes: data.endcob_pes || null,
    comcob_pes: data.comcob_pes || null,
    muncob_pes: data.muncob_pes || null,
    barcob_pes: data.barcob_pes || null,
    cliata_pes: data.cliata_pes || null,
    parcei_pes: data.parcei_pes || null,
    municipioComercial: data.municipioComercial || {},
    municipioCobranca: data.municipioCobranca || {},
    pais: data.pais || {},
    estado: data.estado || {},
    municipio: data.municipio || {},
    empresa: data.empresa || {},
    usuarioInsercao: data.usuarioInsercao || {},
    usuarioAlteracao: data.usuarioAlteracao || {},
    modalidadeVenda: data.modalidadeVenda || {},
    limcre_pes: data.limcre_pes || null,
    idrad_pes: data.idrad_pes || null,
    classificacao: data.classificacao || {},
    grupo: data.grupo || {},
    categoria: data.categoria || {},
    tabelaDesconto: data.tabelaDesconto || {},
    autorizacaoContatos: data.autorizacaoContatos || [],
    codigo_pef: data.codigo_pef || null,
    codcon_pef: data.codcon_pef || null,
    datnas_pef: data.datnas_pef ? new Date(moment(data.datnas_pef).local()) : data?.pessoaf?.datnas_pef ? new Date(moment(data?.pessoaf?.datnas_pef).local()) : null,
    estciv_pef: data.estciv_pef || null,
    nacion_pef: data.nacion_pef || null,
    natura_pef: data.natura_pef || null,
    sex_pef: data.sex_pef || null,
    obs_pef: data.obs_pef || null,
    nompai_pef: data.nompai_pef || null,
    nommae_pef: data.nommae_pef || null,
    pejtra_pef: data.pejtra_pef || null,
    nomtra_pef: data.nomtra_pef || null,
    endtra_pef: data.endtra_pef || null,
    numtra_pef: data.numtra_pef || null,
    baitra_pef: data.baitra_pef || null,
    ceptra_pef: data.ceptra_pef || null,
    muntra_pef: data.muntra_pef || null,
    teltra_pef: data.teltra_pef || null,
    codpro_pef: data.codpro_pef || null,
    temser_pef: data.temser_pef || null,
    refpes_pef: data.refpes_pef || null,
    temres_pef: data.temres_pef || null,
    orgemi_pef: data.orgemi_pef || null,
    datemi_pef: data.datemi_pef || null,
    cnh_pef: data.cnh_pef || null,
    nuctps_pef: data.nuctps_pef || null,
    sectps_pef: data.sectps_pef || null,
    numpis_pef: data.numpis_pef || null,
    renmes_pef: data.renmes_pef || null,
    codesc_pef: data.codesc_pef || null,
    codhob_pef: data.codhob_pef || null,
    naturalidade: data.naturalidade || {},
    profissao: data.profissao || {},
    escolaridade: data.escolaridade || {},
    hobby: data.hobby || {},
    conjuge: data.conjuge || {},
    empresaTrabalho: data.empresaTrabalho ? {
      codigo_pej: data.empresaTrabalho.codigo_pej,
      nomraz_pes: data.empresaTrabalho?.pessoa?.nomraz_pes
    } : (data.empresa || {}),
    usuarioInsercao: data.usuarioInsercao || {},
    usuarioAlteracao: data.usuarioAlteracao || {},
    cliente: data.cliente || {},
    municipioTrabalho: data.municipioTrabalho || {},
    diapag_cli: data.diapag_cli || null,
    tipoPreco: data.tipoPreco || [],
    representantes: data.representantes || [],
    ...data.pessoa,
    ...data.pessoaFisica
  }
}

export const postPessoaFisicaDTO = (data) => {
  return {
    codtpr_pes: data.tipoPreco?.codigo_tpr || null,
    codigo_pes: data.codigo_pef || null,
    nomraz_pes: data.nomraz_pes || null,
    apelid_pes: data.apelid_pes || null,
    end_pes: data.end_pes || null,
    bairro_pes: data.bairro_pes || null,
    cidade_pes: data.cidade_pes || null,
    uf_pes: data.uf_pes || null,
    pais_pes: data.pais_pes || null,
    cep_pes: CommonHelper.clearDigitsSpecialChars(data.cep_pes) || null,
    email_pes: data.email_pes ? data.email_pes.toLowerCase() : '',
    emafin_pes: data.emafin_pes ? data.emafin_pes.toLowerCase() : null,
    numend_pes: data.numend_pes || null,
    codemp_pes: data.codemp_pes || null,
    cgccpf_pes: CommonHelper.clearDigitsSpecialChars(data.cgccpf_pes) || null,
    idint_pes: CommonHelper.clearDigitsSpecialChars(data.idint_pes) || null,
    ierg_pes: CommonHelper.clearDigitsSpecialChars(data.ierg_pes) || null,
    ie_pes: data.ie_pes || '',
    rg_pes: data.rg_pes || null,
    insmun_pes: data.insmun_pes || null,
    telcel_pes: CommonHelper.clearDigitsSpecialChars(data.telcel_pes) || null,
    telcom_pes: CommonHelper.clearDigitsSpecialChars(data.telcom_pes) || null,
    telres_pes: CommonHelper.clearDigitsSpecialChars(data.telres_pes) || null,
    idrad_pes: CommonHelper.clearDigitsSpecialChars(data.idrad_pes) || null,
    codmov_pes: data.modalidadeVenda?.codigo_mov || null,
    comend_pes: data.comend_pes || null,
    codmun_pes: data.codmun_pes || null,
    codpai_pes: data.codpai_pes || null,
    usuins_pes: data.usuins_pes || null,
    refban_pes: data.refban_pes || '',
    datins_pes: formatDateToAmerican(data.datins_pes || new Date()),
    numcom_pes: data.numcom_pes || null,
    cepcom_pes: data.cepcom_pes || null,
    endcom_pes: data.endcom_pes || null,
    comcom_pes: data.comcom_pes || null,
    muncom_pes: data.municipioComercial?.id || null,
    barcom_pes: data.barcom_pes || null,
    numcob_pes: data.numcob_pes || null,
    cepcob_pes: data.cepcob_pes || null,
    endcob_pes: data.endcob_pes || null,
    comcob_pes: data.comcob_pes || null,
    muncob_pes: data.municipioCobranca?.id || null,
    barcob_pes: data.barcob_pes || null,
    cliata_pes: data.cliata_pes || false,
    parcei_pes: data.parcei_pes || false,
    codgup_pes: data.grupo?.codigo_gup || null,
    codcat_pes: data.categoria?.codigo_cat || null,
    codcap_pes: data.classificacao?.codigo_cap || null,
    codtad_pes: data.tabelaDesconto?.codigo_tad || null,
    codigo_pef: data.codigo_pef || null,
    codcon_pef: data.conjuge.codigo_pef || null,
    datnas_pef: data.datnas_pef ? CommonHelper.dateToAmerican(data.datnas_pef) : null,
    estciv_pef: data.estciv_pef || 7,
    nacion_pef: data.nacion_pef || null,
    natura_pef: data.naturalidade?.id || null,
    sex_pef: data.sex_pef || null,
    obs_pef: data.obs_pef || null,
    nompai_pef: data.nompai_pef || null,
    nommae_pef: data.nommae_pef || null,
    pejtra_pef: data.empresaTrabalho?.codigo_pej || null,
    nomtra_pef: data.nomtra_pef || null,
    endtra_pef: data.endtra_pef || null,
    numtra_pef: data.numtra_pef || null,
    baitra_pef: data.baitra_pef || null,
    ceptra_pef: CommonHelper.clearDigitsSpecialChars(data.ceptra_pef) || null,
    muntra_pef: data.municipioTrabalho?.id || null,
    teltra_pef: CommonHelper.clearDigitsSpecialChars(data.teltra_pef) || null,
    codpro_pef: data.profissao.codigo_pro || null,
    temser_pef: data.temser_pef || null,
    refpes_pef: data.refpes_pef || null,
    temres_pef: data.temres_pef || null,
    orgemi_pef: data.orgemi_pef || null,
    datemi_pef: data.datemi_pef ? CommonHelper.dateToAmerican(new Date(data.datemi_pef)) : null,
    cnh_pef: data.cnh_pef || null,
    nuctps_pef: data.nuctps_pef || null,
    sectps_pef: data.sectps_pef || null,
    numpis_pef: data.numpis_pef || null,
    renmes_pef: data.renmes_pef || null,
    codesc_pef: data.escolaridade?.codigo_esc || null,
    codhob_pef: data.hobby.codigo_hob || null,
    diapag_cli: data.diapag_cli || null,
    autorizacaoContatos: data.autorizacaoContatos || [],
    representantes: data.representantes || null
  }
}
