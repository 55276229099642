import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import CorService from '../../../../services/cadastro/veiculo/CorService'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import RequiredLabel from '../../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { classNames } from 'primereact/utils'

const DadosPrincipaisCor = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const [marcas, setMarcas] = useState([])
  const [coresDenatran, setCoresDenatran] = useState([])

  useEffect(async () => {
    await getMarcas()
    await getCoresDenatran()
  }, [])

  const getMarcas = async () => {
    try {
      await CorService.getAllMarcas().then(marcas => setMarcas(marcas))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as marcas')
    }
  }

  const getCoresDenatran = async () => {
    try {
      await CorService.getAllCoresDenatran().then(coresDenatran => setCoresDenatran(coresDenatran))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as cores!')
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-6">
      <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_cor')} />
        <TextInput
          placeholder="Descrição"
          name="descri_cor"
          className={classNames({ 'p-invalid': isFormFieldValid('descri_cor') }, 'inputfield w-full')}
          value={form.values.descri_cor}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('descri_cor')}
        />
        <FieldErrorMessage message={getFormErrorMessage('descri_cor')} />

      </div>
      <div className="field col-6">
      <RequiredLabel label="Marca" valid={isFormFieldValid('marca')} />
        <SelectInput
          value={form.values.marca}
          options={marcas}
          onChange={form.handleChange}
          name="marca"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_mar"
          dataKey="codigo_mar"
          className={classNames({ 'p-invalid': isFormFieldValid('marca') }, 'inputfield w-full')}
          noFloatLabel
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('marca')}
          showClear={form.values.marca?.codigo_mar}
        />
        <FieldErrorMessage message={getFormErrorMessage('marca')} />
      </div>
      <div className="field col-6">
         <RequiredLabel label="Cód. montadora" valid={isFormFieldValid('cormar_cor')} />
        <TextInput
          placeholder="Cód. montadora"
          name="cormar_cor"
          className={classNames({ 'p-invalid': isFormFieldValid('cormar_cor') }, 'inputfield w-full')}
          value={form.values.cormar_cor}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('cormar_cor')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cormar_cor')} />
      </div>
      <div className="field col-6">
        <RequiredLabel label="Cor DENATRAN" valid={isFormFieldValid('corDenatran')} />
        <SelectInput
          value={form.values.corDenatran}
          options={coresDenatran}
          onChange={form.handleChange}
          name="corDenatran"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_cde"
          dataKey="codigo_cde"
          className={classNames({ 'p-invalid': isFormFieldValid('corDenatran') }, 'inputfield w-full')}
          noFloatLabel
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('corDenatran')}
          showClear={form.values.corDenatran?.codigo_cde}
        />
        <FieldErrorMessage message={getFormErrorMessage('corDenatran')} />
      </div>
      <div className="col-12 md:col-12">
        <div className="p-text-right" style={{ color: 'rgb(70, 70, 70)' }}>
          Ao importar uma nota fiscal de terceiro de veículo, o sistema procura pela descrição da cor
          ou código do DENATRAN, se não achar, a cor é automaticamente cadastrada.
        </div>
      </div>
    </div>
  )
}

export default DadosPrincipaisCor
