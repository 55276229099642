import React from 'react'
import DadosPrincipaisAce from './formPages/DadosPrincipaisAce'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'

const AcessorioForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew }) => {
  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisAce
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default AcessorioForm
