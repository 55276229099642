import ServiceBase from '../../base/ServiceBase'

export default class ContaBancariaService {
  static async getAll () {
    return await ServiceBase.getBase('contasBancarias')
  }

  static async filterContasBancarias (query) {
    return await ServiceBase.getBase(`contasBancarias/filter?${query}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`contaBancaria?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutocomplete (query) {
    return await ServiceBase.getBase('contaBancaria?name=' + query, false)
  }
}
