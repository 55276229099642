import React, { useRef } from 'react'
import Message from '../../../components/utils/Message'
import TextInput from '../../../components/inputs/TextInput'
import AppButton from '../../../components/layout/AppButton'
import Modal from '../../../components/utils/Modal'
import './_landingPix.scss'
import { Toast } from 'primereact/toast'

const SharePixModal = (props) => {
  const toast = useRef(null)

  const onHide = () => {
    props.hide()
  }
  const shareOnWhatsApp = () => {
    const currentUrl = window.location.href
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`
    window.open(whatsappURL, '_blank')
  }

  return (
    <>
      <Modal
        header="Compartilhar link"
        visible={props.visible}
        width={40}
        onHide={onHide}
      >
        <div className="field col-12 gap-10">
          <label>Copiar link</label>
          <div>
            <div className="p-inputgroup">
              <TextInput
                className="inputfield w-full"
                value={window.location.href}
              />
              <AppButton
                icon="pi pi-clone"
                onClick={() => navigator.clipboard.writeText(window.location.href)}
                tooltip="Link copiado"
                tooltipOptions={{
                  event: 'focus',
                  hideDelay: 100,
                  position: 'top',
                  className: 'link-tooltip'
                }}
              />
            </div>
          </div>
        </div>
        <div className="field col-12 gap-10" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '13px' }} onClick={() => shareOnWhatsApp()}>
            <label style= {{ textAlign: 'center' }}>Compartilhar no Whatsapp</label>
            <AppButton
              icon="pi pi-send"
            />
          </div>
        </div>
      </Modal>
      <Message />
      <Toast ref={toast} />
    </>
  )
}

export default SharePixModal
