import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getAcessorioDTO } from '../../../../dtos/cadastro/veiculo/AcessorioDTO'
import AcessorioForm from '../../../../forms/cadastro/veiculo/acessorio/AcessorioForm'
import AcessorioService from '../../../../services/cadastro/veiculo/AcessorioService'
import { AcessorioValidator } from '../../../../forms/cadastro/veiculo/acessorio/AcessorioValidator'

export function Acessorio (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={AcessorioForm}
      valida={AcessorioValidator}
      modelGetDTO={getAcessorioDTO}
      service={AcessorioService}
      resource="acessorios"
      primarykeyname="codigo_ace"
      formTitle="Acessório"
    />
  )
}
