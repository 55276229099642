import CommonHelper from "../../../helpers/CommonHelper";
import AppButton from "../AppButton";
import DropdownMenu from "../DropdownButton/DropdownMenu";

export default function BotoesHeaderForm({
  isNew,
  isNewCrud,
  apenasVisualizacao,
  mostrarBotaoAcoes,
  actions,
  editar,
  habilitaButtonEditar,
  onSave,
  form,
  setVisibleCancelarModal,
  setVisibleConfirmModal,
  edicaoHabilitada
}) {
  if ((!isNew || !isNewCrud) && !apenasVisualizacao) {
    if (!mostrarBotaoAcoes) return null;
    if (actions && editar) {

      return (
        <div className="flex justify-content-center align-items-center">
          <div>
            <AppButton
              type="button"
              label={CommonHelper.isDesktop() ? 'Editar' : ''}
              className="mr-2"
              icon="pi pi-pencil"
              onClick={() => habilitaButtonEditar(!edicaoHabilitada)}
            />
          </div>
          <DropdownMenu
            items={actions}
            icon="pi pi-bars"
            label={CommonHelper.isDesktop() ? 'Ações' : ''}
            disabled={edicaoHabilitada}
          />
        </div>
      );
    }

    if (actions && !editar) {
      return (
        <div className="flex justify-content-end align-items-center">
          {edicaoHabilitada ? (
            <>
              <AppButton
                type="submit"
                label={CommonHelper.isDesktop() ? 'Salvar' : ''}
                icon="pi pi-check"
                className="mr-2"
                onClick={async () => await onSave(form.values)}
              />
              <AppButton
                type="button"
                label={CommonHelper.isDesktop() ? 'Cancelar' : ''}
                className="p-button-danger mr-2"
                icon="pi pi-times"
                onClick={() => {
                  setVisibleCancelarModal(true)
                }}
              />
            </>
          ) : null}
          <DropdownMenu
            items={actions}
            icon="pi pi-bars"
            label={CommonHelper.isDesktop() ? 'Ações' : ''}
            disabled={edicaoHabilitada}
          />
        </div>
      );
    }


    return (
      <div className="flex">
        {
          !edicaoHabilitada
            ? <AppButton
              label={CommonHelper.isDesktop() ? 'Excluir' : ''}
              className="p-button-danger mr-2"
              icon="pi pi-times"
              type="button"
              onClick={() => setVisibleConfirmModal(true)}
              disabled={edicaoHabilitada}
            />
            : <AppButton
              label={CommonHelper.isDesktop() ? 'Cancelar' : ''}
              className="p-button-danger mr-2"
              icon="pi pi-times"
              type="button"
              onClick={() => {
                setVisibleCancelarModal(true)
              }}
            />
        }
        {
          !edicaoHabilitada
            ? <AppButton
              type="button"
              label={CommonHelper.isDesktop() ? 'Editar' : ''}
              className={"mr-2"}
              icon={"pi pi-pencil"}
              onClick={() => habilitaButtonEditar(!edicaoHabilitada)}
            />
            : <AppButton
              type="submit"
              label={CommonHelper.isDesktop() ? 'Salvar' : ''}
              icon="pi pi-check"
              className="mr-2"
              onClick={async () => await onSave(form.values)}
            />

        }
      </div>
    );

  }
  return (
    <AppButton
      type="submit"
      label={CommonHelper.isDesktop() ? 'Salvar' : ''}
      icon="pi pi-check"
      className="mr-2"
      onClick={async () => await onSave(form.values)}
    />
  )
};
