
export const getRepresentanteDTO = (data = {}) => {
  return {
    codigo_rep: data.codigo_rep || null,
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null
  }
}

export const postRepresentanteDTO = (data) => {
  return {
    codigo_rep: data.codigo_rep || null,
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null
  }
}
