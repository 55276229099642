import React, { Component } from 'react'
import DadosPrincipaisCor from './formPages/DadosPrincipaisCor'
import TabMenuContainer from '../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../components/layout/TabMenuContainer/TabMenuPage'

const CorForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew }) => {
  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisCor
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default CorForm
