import React, { useEffect, useState } from 'react'

import { InputTextarea } from 'primereact/inputtextarea'
import { classNames } from 'primereact/utils'
import DateInput from '../../../../components/inputs/DateInput'
import SelectButtons from '../../../../components/inputs/SelectButton'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import { baseForm } from '../../../../components/utils/BaseForm'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import RequiredLabel from '../../../../components/utils/RequiredLabel'
import { getDocumentoDTO, postDocumentoDTO } from '../../../../dtos/vendas/negociacao/DocumentoDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDocument, formatToCurrency } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import { getStore } from '../../../../redux/store'
import DocumentosService from '../../../../services/cadastro/documento/DocumentosService'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import { DocumentoValidator } from './DocumentoValidator'
const DocumentoModal = ({ edicaoHabilitada, veiculoNve, hide, selected, formNeg, atualizaTotalDocumentos, visible }) => {
  const camposObrigatorios = DocumentoValidator._nodes
  const isNewDocument = Object.keys(selected).length === 0

  const handleSubmit = (data) => {
    adicionarDocumento(postDocumentoDTO(data))
    hide()
  }
  const form = baseForm({
    initialValues: getDocumentoDTO({ veiculoNve }),
    validationSchema: DocumentoValidator,
    onSubmit: handleSubmit
  })
  const optionsPagamentoCortesia = ['Cortesia da loja', 'Pago pelo cliente']
  const [sugestaoDestinosPagar, setSugestaoDestinosPagar] = useState([])
  const [sugestaoDestinosReceber, setSugestaoDestinosReceber] = useState([])
  const [sugestaoTiposDocumentos, setSugestaoTiposDocumentos] = useState([])
  const [valorConfig1232, setValorConfig1232] = useState(true)
  const [valorConfig3237, setValorConfig3237] = useState(false)
  const [valorConfig1145, setValorConfig1145] = useState(false)
  const [obrigaCusto, setObrigaCusto] = useState(false)
  const [custoFixo, setCustoFixo] = useState(false)
  const [editarDocAutomatico, setEditarDocAutomatico] = useState(false)
  const { pessoa } = getStore().empresaLogada

  useEffect(async () => {
    const config1232 = await ConfigService.getValor(1232, false)
    const config3237 = await ConfigService.getValor(3237, false)
    const config1145 = await ConfigService.getValor(1145, false)
    setValorConfig1232(config1232)
    setValorConfig3237(config3237)
    setValorConfig1145(config1145)
  }, [])
  useEffect(async () => {
    if (isNewDocument) {
      await form.setValues(getDocumentoDTO({ veiculoNve }))
    } else {
      await form.setValues(getDocumentoDTO(selected))
    }
    setObrigaCusto((form.values.obrigaCusto || selected.tipoDocumento?.obrcus_tdo))
    setCustoFixo((form.values.custoFixo || selected.tipoDocumento?.cusfix_tdo))
    const { documents } = formNeg.values
    const indexDocuments = documents.indexOf(selected)
    setEditarDocAutomatico(documents[indexDocuments]?.addaut_ndo)
    validaDataVencimento()
  }, [isNewDocument])
  useEffect(() => {
    if (visible && isNewDocument) {
      form.resetForm()
    }
  }, [visible])

  const handleChangeValorCobrado = async (e) => {
    const valor_ndo = e.target.value

    form.setFieldValue('valor_ndo', formatCurrencyToNumber(valor_ndo))

    if (!form.values.custoFixo && valorConfig1232) {
      form.setFieldValue('valcus_ndo', formatCurrencyToNumber(valor_ndo))
    }
  }

  const handleChangeTipoDocumento = async (e) => {
    form.setFieldValue('tipoDocumento', e.target.value)
    form.setFieldValue('tipoDocumento.descri_tdo', e.target.value)
  }

  function onSaveModalDestinoPagar (destino) {
    onSelectDestinoPagar({ value: destino.pessoa })
  }
  function onSaveModalDestinoReceber (destino) {
    onSelectDestinoReceber({ value: destino.pessoa })
  }

  const handleChangeDestinoPagar = async (e) => {
    form.setFieldValue('destinoPagar', e.target.value)
    form.setFieldValue('destinoPagar.nomraz_pes', e.target.value)
    formNeg.setFieldValue('destinoPagar', e.target.value)
    formNeg.setFieldValue('destinoPagar.nomraz_pes', e.target.value)
  }
  const handleChangeDestinoReceber = async (e) => {
    form.setFieldValue('destinoReceber', e.target.value)
    form.setFieldValue('destinoReceber.nomraz_pes', e.target.value)
    formNeg.setFieldValue('destinoReceber', e.target.value)
    formNeg.setFieldValue('destinoReceber.nomraz_pes', e.target.value)
  }

  const handleChangeDataVencimento = async (e) => {
    form.handleChange(e)
    await validaDataVencimento()
  }

  const validaDataVencimento = async () => {
    await form.setFieldTouched('datpag_ndo')
    await form.validateField('datpag_ndo')
  }

  const handleChangeValorCusto = async (e) => {
    form.handleChange(e)
    form.setFieldValue('valcus_ndo', formatCurrencyToNumber(e.target.value))
    form.validateField('valcus_ndo')
    if (!e.target.value) {
      form.setFieldValue('valcus_ndo', 0)
    }
  }

  const sugerirDestinoPagar = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.destinoPagar.nomraz_pes)

      setSugestaoDestinosPagar(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um destinatário!')
    }
  }
  const sugerirDestinoReceber = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.destinoReceber.nomraz_pes)

      setSugestaoDestinosReceber(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um destinatário!')
    }
  }

  const sugerirTiposDocumentos = async () => {
    try {
      const tiposDocumentos = await DocumentosService.filterTiposDocumentos(`descricaoFiltro=${form.values.tipoDocumento.descri_tdo}`)
      setSugestaoTiposDocumentos(tiposDocumentos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um tipo de documento!')
    }
  }

  const onSelectDestinoPagar = async (e) => {
    await form.setFieldValue('destinoPagar', e.value)
  }

  const onSelectDestinoReceber = async (e) => {
    await form.setFieldValue('destinoReceber', e.value)
  }

  const onSelectTipoDocumento = async (e) => {
    try {
      const tipoDocumentoSelecionado = e.value
      const tipoDocumento = await DocumentosService.getTipoDocumento(tipoDocumentoSelecionado.codigo_tdo)
      if (!tipoDocumento.ativo_tdo) {
        throw Error(`O tipo de documento ${tipoDocumento.descri_tdo} está inativo! Selecione outro ou altere o seu cadastro`)
      }
      form.setFieldValue('tipoDocumento', tipoDocumentoSelecionado)

      if (tipoDocumento.valcus_tdo) {
        form.setFieldValue('valcus_ndo', tipoDocumento.valcus_tdo)
      }
      setObrigaCusto(tipoDocumento.obrcus_tdo)
      setCustoFixo(tipoDocumento.cusfix_tdo)
      form.setFieldValue('destinoReceber',
        tipoDocumento.pessoaRecebimentoTitulo !== null
          ? tipoDocumento.pessoaRecebimentoTitulo
          : Object.keys(form.values.destinoReceber).length > 0
            ? form.values.destinoReceber
            : pessoa
      )
      form.setFieldValue('destinoPagar',
        tipoDocumento.pessoaPagamentoTitulo !== null
          ? tipoDocumento.pessoaPagamentoTitulo
          : Object.keys(form.values.destinoPagar).length > 0
            ? form.values.destinoPagar
            : ''
      )
      form.setFieldValue('custoFixo', tipoDocumento.cusfix_tdo)
      form.setFieldValue('obrigaCusto', Boolean(tipoDocumento.obrcus_tdo))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao selecionar o tipo de documento!')
    }
  }

  const adicionarDocumento = (documento) => {
    const { documents } = formNeg.values
    if (isNewDocument) {
      documents.push({ ...documento, idx: documents.length })
    } else {
      const indexDocuments = documents.indexOf(selected)
      documents.splice(indexDocuments, 1, documento)
    }
    formNeg.setFieldValue('documents', documents)
    atualizaTotalDocumentos()
  }

  const salvarDocumentos = async () => {
    const { documents } = formNeg.values
    const indexDocuments = documents.indexOf(selected)
    if ((valorConfig1145 || !valorConfig3237) && form.values.datpag_ndo < form.values.datemi_ndo) {
      showWarnMessage('A data de vencimento do documento não pode ser menor que a data de emissão!')
      return false
    }
    if (documents[indexDocuments]?.addaut_ndo) {
      showWarnMessage('Não é permitido editar um tipo de documento adicionado automática!')
      hide()
      return false
    }
    await form.submitForm()
  }

  const deletarDocumento = () => {
    const { documents } = formNeg.values
    const indexDocuments = documents.indexOf(selected)
    if (documents[indexDocuments].addaut_ndo) {
      showWarnMessage('Não é permitido excluir um tipo de documento adicionado automática!')
      hide()
      return false
    }
    documents.splice(indexDocuments, 1)
    formNeg.setFieldValue('documents', documents)
    atualizaTotalDocumentos()
    hide()
  }

  const handleObservacao = (e) => {
    form.setFieldValue('obslan_ndo', CommonHelper.removerCaracteresEspeciais(e.target.value))
  }
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  return (
    <Modal
      header="Documento"
      width="40"
      visible={visible}
      modal={true}
      onHide={hide}
      btnSalvar={edicaoHabilitada ? salvarDocumentos : undefined}
      btnExcluir={(!isNewDocument && edicaoHabilitada) ? deletarDocumento : undefined}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <SelectButtons className='SelectButtons' options={optionsPagamentoCortesia}
            disabled={!edicaoHabilitada || editarDocAutomatico}
            value={form.values.respag_ndo === 1 ? optionsPagamentoCortesia[1] : optionsPagamentoCortesia[0] }
            onChange={(e) => {
              if (e.value !== null) {
                if (e.value === 'Cortesia da loja') {
                  form.setFieldValue('respag_ndo', 2)
                  form.setFieldValue('valor_ndo', 0)
                } else {
                  form.setFieldValue('respag_ndo', 1)
                }
              }
            }}/>
        </div>
        <div className="field col-12">
          <SearchInput
            //AutoComplete
            field="descri_tdo"
            label="Tipo de documento"
            value={form.values.tipoDocumento && form.values.tipoDocumento.descri_tdo}
            placeholder="Tipo de documento"
            disabled={!edicaoHabilitada || editarDocAutomatico}
            onSelect={async (e) => { await onSelectTipoDocumento(e); form.setFieldTouched('tipoDocumento.codigo_tdo') }}
            onBlur={() => form.setFieldTouched('tipoDocumento.codigo_tdo')}
            suggestions={sugestaoTiposDocumentos}
            completeMethod={sugerirTiposDocumentos}
            onChange={handleChangeTipoDocumento}
            className={classNames({ 'p-invalid': isFormFieldValid('tipoDocumento.codigo_tdo') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('tipoDocumento.codigo_tdo')}
            required
            //FiltroModal
            filtrotitle="Pesquisa de tipo de documento"
            service={DocumentosService}
            model={getDocumentoDTO}
            primarykeyname="codigo_tdo"
            columns={[
              { campo: 'codigo_tdo', nome: 'Código' },
              { campo: 'descri_tdo', nome: 'Nome' }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('tipoDocumento.codigo_tdo')} />
        </div>

        <div className="field col-12 relative">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Destino (Pagar)"
            value={form.values.destinoPagar?.nomraz_pes && form.values.destinoPagar}
            placeholder="Nome do destino"
            disabled={!edicaoHabilitada || editarDocAutomatico}
            onSelect={async (e) => { await onSelectDestinoPagar(e); form.setFieldTouched('destinoPagar.codigo_pes') }}
            onBlur={() => form.setFieldTouched('destinoPagar.codigo_pes')}
            suggestions={sugestaoDestinosPagar}
            completeMethod={sugerirDestinoPagar}
            onChange={handleChangeDestinoPagar}
            itemTemplate={PessoaAutocompleteTemplate}
            className={classNames({ 'p-invalid': isFormFieldValid('destinoPagar.codigo_pes') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('destinoPagar.codigo_pes')}
            required
            //RegisterModal
            selected={form.values.destinoPagar}
            onSaveModal={onSaveModalDestinoPagar}
            onDeleteModal={async () => await onSelectDestinoPagar({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.destinoPagar?.codigo_pes ? 'Pessoa Física - ' + form.values.destinoPagar.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.destinoPagar?.codigo_pes ? 'Pessoa jurídica - ' + form.values.destinoPagar.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            //FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
          <div className="label-over-cadastro">
            {formatDocument(form.values.destinoPagar?.cgccpf_pes)}
          </div>
          <FieldErrorMessage message={getFormErrorMessage('destinoPagar.codigo_pes')} />
        </div>

        {(form.values.respag_ndo === 1 || form.values?.addaut_ndo) &&
        <div className="field col-12 relative">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Destino (Receber)"
            value={form.values.destinoReceber?.nomraz_pes && form.values.destinoReceber}
            placeholder="Nome do destino"
            disabled={!edicaoHabilitada || editarDocAutomatico}
            onSelect={async (e) => { await onSelectDestinoReceber(e); form.setFieldTouched('destinoReceber.codigo_pes') }}
            onBlur={() => form.setFieldTouched('destinoReceber.codigo_pes')}
            suggestions={sugestaoDestinosReceber}
            completeMethod={sugerirDestinoReceber}
            onChange={handleChangeDestinoReceber}
            itemTemplate={PessoaAutocompleteTemplate}
            className={classNames({ 'p-invalid': isFormFieldValid('destinoReceber') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('destinoReceber')}
            required
            //RegisterModal
            selected={form.values.destinoReceber}
            onSaveModal={onSaveModalDestinoReceber}
            onDeleteModal={async () => await onSelectDestinoReceber({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.destinoReceber?.codigo_pes ? 'Pessoa Física - ' + form.values.destinoReceber?.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.destinoReceber?.codigo_pes ? 'Pessoa jurídica - ' + form.values.destinoReceber?.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            //FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
          <div className="label-over-cadastro">
            {formatDocument(form.values.destinoReceber?.cgccpf_pes)}
          </div>
          <FieldErrorMessage message={getFormErrorMessage('destinoReceber')} />
        </div>
        }
        <div className="field col-12 md:col-6">
          <DateInput
            label="Data de emissão"
            name="datemi_ndo"
            disabled={!edicaoHabilitada || editarDocAutomatico}
            value={form.values.datemi_ndo}
            onChange={form.handleChange}
            placeholder="Data de emissão"
            className="inputfield w-full" />
        </div>
        <div className="field col-12 md:col-6">
          <DateInput
            name="datpag_ndo"
            label="Data de vencimento"
            disabled={!edicaoHabilitada || editarDocAutomatico}
            value={form.values.datpag_ndo}
            onChange={handleChangeDataVencimento}
            placeholder="Data de vencimento"
            camposObrigatorios={camposObrigatorios}
            form={form}
            inputTooltip="O campo é obrigatório apenas quando algum valor for informado no documento!"
          />
        </div>
        {form.values.respag_ndo === 1 &&
        <div className="field col-6">
          { form.values?.respag_ndo === 1 && <RequiredLabel label="Valor cobrado" valid={isFormFieldValid('valor_ndo')} /> }
          <TextInput
            label={`${form.values?.respag_ndo === 1 ? '' : 'Valor cobrado'}`}
            value={formatToCurrency(form.values.valor_ndo)}
            onChange={handleChangeValorCobrado}
            disabled={!edicaoHabilitada || editarDocAutomatico}
            className={classNames({ 'p-invalid': isFormFieldValid('valor_ndo') }, 'inputfield w-full')}
            onBlur={validaDataVencimento}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor_ndo')} />

        </div>
        }
        <div className="field col-6">
          { obrigaCusto && !custoFixo && <RequiredLabel label="Valor de custo" valid={isFormFieldValid('valcus_ndo')} /> }
          <TextInput
            label={obrigaCusto && !custoFixo ? '' : 'Valor de custo'}
            value={formatToCurrency(form.values.valcus_ndo)}
            onChange={handleChangeValorCusto}
            disabled={!edicaoHabilitada || valorConfig1232 || custoFixo || editarDocAutomatico}
            className={classNames({ 'p-invalid': isFormFieldValid('valcus_ndo') }, 'inputfield w-full')}
            inputTooltip={obrigaCusto && custoFixo ? 'Esse tipo de documento está configurado com um custo fixo em seu cadastro!' : ''}

          />
          <FieldErrorMessage message={getFormErrorMessage('valcus_ndo')} />

        </div>
        {formNeg.values.veiculosConjunto.length > 0 && (
          <div className="field col-12 md:col-6">
            <SelectInput
              value={form.values.veiculoNve}
              options={formNeg.values.veiculosConjunto}
              onChange={(e) => form.setFieldValue('veiculoNve', e.target.value)}
              label="Veículo"
              optionLabel="placha"
              dataKey="codvei_nve"
              disabled={!edicaoHabilitada || editarDocAutomatico}
              className="inputfield w-full"
              noFloatLabel
            />
          </div>
        )}
        <div className="field col-12 disabled-input">
          <label className="label">Observações</label>
          <InputTextarea label="Observações" placeholder="Observações"
            name="obslan_ndo" value={form.values.obslan_ndo} onChange={handleObservacao}
            disabled={!edicaoHabilitada || editarDocAutomatico} autoResize className="inputfield w-full" />
        </div>
      </div>
    </Modal>
  )
}

export default DocumentoModal
