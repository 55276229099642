import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../../../../components/inputs/DateTimeInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import AppButton from '../../../../../components/layout/AppButton'
import BtnOption from '../../../../../components/layout/BtnOption'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatDocument, formatPlaca, formatPlacha } from '../../../../../helpers/formaters'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import EmpresasService from '../../../../../services/cadastro/pessoa/EmpresasService'
import PermissaoService from '../../../../../services/cadastro/pessoa/PermissaoService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import RestricaoService from '../../../../../services/cadastro/pessoa/RestricaoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import ReportService from '../../../../../services/reports/ReportService'
import NegociacaoService from '../../../../../services/vendas/negociacao/NegociacaoService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'

const ImpressoesNegModal = ({ form, visibleImpressoesNegModal, hide, operacaoNeg }) => {
  const [permissao62, setPermissao62] = useState(true)
  const [config3220, setConfig3220] = useState(false)
  const [config3241, setConfig3241] = useState(false)
  const [config3233, setConfig3233] = useState(false)
  const [config3323, setConfig3323] = useState(false)
  const [config3279, setConfig3279] = useState(false)
  const [visibleDataEntregaModal, setVisibleDataEntregaModal] = useState(false)
  const [dataHoraEntregaVeiculo, setDataHoraEntregaVeiculo] = useState(null)
  const [entreguePara, setEntreguePara] = useState([])
  const [sugestaoPessoas, setSugestaoPessoas] = useState([])
  const [visibleEntreguePara, setVisibleEntreguePara] = useState(false)

  const [restricao22, setRestricao22] = useState(false)
  const [restricao45, setRestricao45] = useState(false)

  const codigoNegociacao = form.values.codigo_neg
  const codigoVeiculo = form.values.negociacaoVeiculo?.codvei_nve

  useEffect(async () => {
    await ConfigService.getValor(3220).then(config3220 => setConfig3220(config3220))
    await ConfigService.getValor(3241).then(config3241 => setConfig3241(config3241))
    await ConfigService.getValor(3233).then(config3233 => setConfig3233(config3233))
    await ConfigService.getValor(3323).then(config3323 => setConfig3323(config3323))
    await ConfigService.getValor(3279).then(config3279 => setConfig3279(config3279))
    await PermissaoService.getByCodigo(62).then(permissao62 => setPermissao62(permissao62))
    await RestricaoService.getByCodigo(22).then(restricao22 => setRestricao22(restricao22))
    await RestricaoService.getByCodigo(45).then(restricao45 => setRestricao45(restricao45))
    veiculoConsignado()
    setEntreguePara(form.values?.pessoa)
    form.setFieldValue('entreguePara', form.values?.pessoa)
  }, [])

  const isVenda = () => {
    return operacaoNeg === 'Venda' ? true : false
  }

  const isAprovada = () => {
    if (config3233 && isConsignado()) {
      return form.values.dahaprconsig_neg != null ? true : false
    } else {
      return form.values.dahapr_neg != null ? true : false
    }
  }

  const isConsignado = () => {
    return operacaoNeg === 'Consignação' ? true : false
  }
  const getOperacao = () => {
    return (isVenda() ? 'venda' : isConsignado() ? 'consignação' : 'compra')
  }

  const veiculoConsignado = async () => {
    const veiculoConsignado = await NegociacaoService.getNveCom(form.values?.negociacaoVeiculo.codvei_nve, form.values?.negociacaoVeiculo.nvecom_nve)
    return veiculoConsignado.consig_nve
  }

  const sugerirPessoas = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.entreguePara)

      if (pessoas.length <= 0) {
        showWarnMessage('Pessoa não encontrada!')
      }
      setSugestaoPessoas(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  const imprimirReciboCliente = async () => {
    try {
      let tipo = 'erro'

      form.values.foPagNegs.forEach(foPagNeg => {
        if (!foPagNeg.revpag_fpn) { tipo = 'cliente' }
      })

      if (tipo === 'erro') { throw Error('Não existem formas de pagamento de cliente') }

      const pdf = await ReportService.getRecibo('cliente', codigoNegociacao)
      CommonHelper.openFile('application/pdf', pdf, `recibo-cliente-negociacao-${codigoNegociacao}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o recibo do cliente!')
    }
  }

  const imprimirReciboRevenda = async () => {
    try {
      let tipo = 'erro'

      form.values.foPagNegs.forEach(foPagNeg => {
        if (foPagNeg.revpag_fpn) {
          tipo = 'revenda'
        }
      })

      if (tipo === 'erro') { throw Error('Não existem formas de pagamento de revenda') }

      const pdf = await ReportService.getRecibo('revenda', codigoNegociacao)
      CommonHelper.openFile('application/pdf', pdf, `recibo-revenda-negociacao-${codigoNegociacao}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o recibo da revenda!')
    }
  }
  const imprimirEnvelope = async () => {
    try {
      const pdf = await ReportService.getEnvelope(codigoNegociacao)
      CommonHelper.openFile('application/pdf', pdf, `envelope-${codigoNegociacao}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o recibo da revenda!')
    }
  }

  const imprimirTermoEntregaSaida = async () => {
    if (form.values.veiculosConjunto.length > 0) {
      for (const veiculoConjunto of form.values.veiculosConjunto) {
        await downloadTermoEntregaSaida(veiculoConjunto)
      }
    } else {
      await downloadTermoEntregaSaida(form.values.negociacaoVeiculo)
    }
  }

  async function downloadTermoEntregaSaida (negociacaoVeiculo) {
    const placha = formatPlacha(negociacaoVeiculo.veiculo.placa_vei, negociacaoVeiculo.veiculo.chassi_vei)
    try {
      let pdf
      if (!!(entreguePara)) {
        pdf = await ReportService.getTermoEntregaSaida(codigoNegociacao, negociacaoVeiculo.codigo_nve, entreguePara.nomraz_pes)
      } else {
        pdf = await ReportService.getTermoEntregaSaida(codigoNegociacao, negociacaoVeiculo.codigo_nve)
      }
      CommonHelper.openFile('application/pdf', pdf, `termo-entrega-saida-${placha}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar imprimir o termo de entrega para o veículo: ${placha}!`)
    }
  }

  const imprimirTermoEntregaEntrada = async () => {
    try {
      let pdf
      if (!!(entreguePara)) {
        pdf = await ReportService.getTermoEntregaEntrada(codigoNegociacao, codigoVeiculo, entreguePara.nomraz_pes)
      } else {
        pdf = await ReportService.getTermoEntregaEntrada(codigoNegociacao, codigoVeiculo)
      }
      CommonHelper.openFile('application/pdf', pdf, `termo-entrega-entrada-${codigoNegociacao}-${codigoVeiculo}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o termo de entrega!')
    }
  }

  const imprimirInstrumentoProcuracao = async () => {
    if (form.values.veiculosConjunto.length > 0) {
      for (const veiculoConjunto of form.values.veiculosConjunto) {
        await downloadInstrumentoProcuracao(veiculoConjunto)
      }
    } else {
      await downloadInstrumentoProcuracao()
    }
  }

  async function downloadInstrumentoProcuracao (negociacaoVeiculo) {
    try {
      const tipo = isVenda() ? 'venda' : 'compra'
      if (isVenda()) {
        const pdf = await ReportService.getInstrumentoProcuracao(tipo, codigoNegociacao, negociacaoVeiculo?.codigo_nve || form.values.negociacaoVeiculo.codigo_nve)

        let infoVeiculo = codigoVeiculo

        if (negociacaoVeiculo) {
          infoVeiculo = formatPlacha(negociacaoVeiculo.veiculo.placa_vei, negociacaoVeiculo.veiculo.chassi_vei)
        }

        CommonHelper.openFile('application/pdf', pdf, `instrumento-procuracao-${tipo}-${codigoNegociacao}-${infoVeiculo}.pdf`)
      } else {
        renderizarHTML('procuracaoCompra', 'html')
      }
    } catch (error) {
      console.error(error)
      let erroMensagem = 'Ocorreu um erro inesperado ao tentar imprimir o instrumento de procuração'

      if (negociacaoVeiculo) {
        erroMensagem += ` para o veículo: ${formatPlacha(negociacaoVeiculo.veiculo.placa_vei, negociacaoVeiculo.veiculo.chassi_vei)}`
      }

      showErrorMessage(error.message || `${erroMensagem}!`)
    }
  }

  const imprimirContratoCompraVenda = async () => {
    if (form.values.status_neg === 3) {
      try {
        let pdf
        if (!!(dataHoraEntregaVeiculo)) {
          pdf = await ReportService.getContratoCompraVenda(codigoNegociacao, CommonHelper.dateTimeToNumber(dataHoraEntregaVeiculo))
        } else {
          pdf = await ReportService.getContratoCompraVenda(codigoNegociacao)
        }

        CommonHelper.openFile('application/pdf', pdf, `contrato-negociacao-${codigoNegociacao}.pdf`)
      } catch (error) {
        console.error(error)
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o contrato da negociação!')
      }
    } else {
      showWarnMessage('Finalize a negociação para imprimir o contrato!')
    }
  }

  const imprimirContratoVendaComGarantia = async () => {
    if (form.values.status_neg === 3) {
      try {
        const pdf = await ReportService.getContratoVendaGarantia(codigoNegociacao)
        CommonHelper.openFile('application/pdf', pdf, `contrato-venda-garantia-${codigoNegociacao}.pdf`)
      } catch (error) {
        console.error(error)
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o contrato da negociação!')
      }
    } else {
      showWarnMessage('Finalize a negociação para imprimir o contrato!')
    }
  }

  const imprimirContratoConsignacao = async () => {
    try {
      const placa = formatPlaca(form.values.negociacaoVeiculo.veiculo.placa_vei)

      const pdf = await ReportService.getContratoConsignacao(codigoNegociacao)
      CommonHelper.openFile('application/pdf', pdf, `contrato-consignacao-${codigoNegociacao}-${placa}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o contrato de consignação!')
    }
  }
  const renderizarHTML = async (report, rota) => {
    let htmlContent = await ReportService.getHTML(codigoNegociacao, report, rota)

    htmlContent += `
    <script>
      window.onload = function() { window.print();}
    </script>`

    const blob = new Blob([htmlContent], { type: 'text/html' })

    const url = URL.createObjectURL(blob)

    window.open(url, '_target')
  }

  const imprimirResumoHTML = async () => {
    try {
      if (restricao45) {
        return showWarnMessage(`Não possui permissão para imprimir resumo de ${getOperacao()}!`)
      }
      renderizarHTML('resumoNegociacao', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o resumo da negociação!')
    }
  }

  const imprimirContratoCompraVendaHTML = async () => {
    try {
      renderizarHTML('contratoCompraVenda', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o resumo da negociação!')
    }
  }

  // const imprimirCheckList = async () => {
  //   try {
  //     const pdf = await ReportService.getCheckList(codigoNegociacao)
  //     CommonHelper.openFile('application/pdf', pdf, 'checklist-sistema.pdf')
  //   } catch (error) {
  //     console.error(error)
  //     showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o check-list!')
  //   }
  // }

  const imprimirCheckListHTML = async () => {
    try {
      renderizarHTML('checkList', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o envelope!')
    }
  }

  const imprimirEnvelopeHTML = () => {
    try {
      renderizarHTML('envelope', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o envelope!')
    }
  }

  const selecionaModeloEnvelope = async () => {
    //depois que estiver todos os contrato, termos em HTML, remover essas configurações
    switch (Number(config3323)) {
    case 1:
      return imprimirEnvelopeHTML()
    case 2:
      return imprimirEnvelope()
    default:
      return imprimirEnvelopeHTML()
    }
  }

  const abrirModalEntregaOuImprimir = async () => {
    let veiculoConsig = await veiculoConsignado()
    switch (Number(config3241)) {
    case 2:
      if (isVenda()) {
        setVisibleDataEntregaModal(true)
      } else {
        imprimirContratoCompraVenda()
      }
      break
    case 3:
      if (isVenda()) {
        return imprimirContratoCompraVendaHTML()
      }
      if (await EmpresasService.verificaFilial(form.values?.pessoa?.codigo_pes)) {
        return imprimirContratoCompraVenda()
      }
      return imprimirContratoCompraVendaHTML()
    case 4:
      return imprimirContratoCompraVendaHTML()
    case 5:
      if (veiculoConsig) {
        return imprimirContratoCompraVendaHTML()
      }
      return imprimirContratoCompraVenda()
    case 7:
      imprimirContratoCompraVendaHTML()
      break
    case 6:
      if (isVenda()) {
        form.setFieldValue('imprimirHTML', true)
        setVisibleDataEntregaModal(true)
      } else {
        imprimirContratoCompraVendaHTML()
      }
      break
    case 8:
      return imprimirContratoCompraVendaHTML()
    default:
      imprimirContratoCompraVenda()
      break
    }
  }

  const abrirModalEntrega = () => {
    setVisibleEntreguePara(true)
  }

  const onSaveEntreguePara = () => {
    if (!entreguePara) {
      showWarnMessage('Por favor informe a pessoa que vai receber o veículo!')
      return
    }
    setVisibleEntreguePara(false)
    isVenda() ? imprimirTermoEntregaSaida() : imprimirTermoEntregaEntrada()
  }

  const onSaveDataEntrega = async (contratoHtml) => {
    if (!dataHoraEntregaVeiculo) {
      showWarnMessage('Por favor informe a data e hora da entrega do veículo!')
      return
    }
    setVisibleDataEntregaModal(false)
    if (form.values?.imprimirHTML) {
      imprimirContratoCompraVendaHTML()
      return
    }
    imprimirContratoCompraVenda()
  }

  const handleChangePessoa = (e) => {
    form.setFieldValue('entreguePara', e.target.value)
    setEntreguePara(e.target.value)
  }

  function onSaveModalPessoa (pessoa) {
    onSelectPessoa({ value: pessoa.pessoa })
  }

  const onSelectPessoa = async (e) => {
    form.setFieldValue('entreguePara.codigo_pes', e.value.codigo_pes)
    form.setFieldValue('entreguePara', e.value)
    setEntreguePara(e.value)
  }
  return (
    <>
      <Modal
        header="Impressões"
        visible={visibleImpressoesNegModal}
        onHide={hide}
        className="impressao-neg-modal"
      >
        <div className="impressao-neg-buttons">
          {/* <BtnOption
            type="button"
            label="Enviar para assinatura digital"
            autoFocus
          /> */}
          <BtnOption
            type="button"
            label={'Contrato de ' + getOperacao()}
            onClick={() => { isConsignado() ? imprimirContratoConsignacao() : abrirModalEntregaOuImprimir() }}
            disabled={!permissao62 && !isAprovada()}
          />
          {(config3220 === true && isVenda()) && (
            <BtnOption
              type="button"
              label={'Contrato de venda com garantia'}
              onClick={() => { imprimirContratoVendaComGarantia() }}
              disabled={!permissao62 && !isAprovada()}
            />
          )}
          {config3279 &&
            <BtnOption
              type="button"
              label={'Check list'}
              onClick={() => imprimirCheckListHTML()}
            />
          }
          <BtnOption
            type="button"
            label={'Resumo de ' + getOperacao() }
            onClick={() => imprimirResumoHTML()}
          />

          <BtnOption
            type="button"
            label="Instrumento de procuração"
            onClick={() => imprimirInstrumentoProcuracao()}
          />
          <BtnOption
            type="button"
            label={isVenda() ? 'Termo de entrega' : 'Termo de recebimento'}
            onClick={() => { abrirModalEntrega() }}
            disabled={isVenda() && (restricao22 || !form.values.negociacaoVeiculo.dahent_nve)}
          />
          <BtnOption
            type="button"
            label="Recibo do cliente"
            onClick={() => imprimirReciboCliente()}
          />
          <BtnOption
            type="button"
            label="Recibo da revenda"
            onClick={() => imprimirReciboRevenda()}
          />
          <BtnOption
            type="button"
            label="Envelope"
            onClick={() => selecionaModeloEnvelope()}
          />
        </div>
      </Modal>
      <Modal
        header="Entrega de veículo"
        visible={visibleDataEntregaModal}
        onHide={() => setVisibleDataEntregaModal(false)}
        width="35"
        modal={true}
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <DateTimeInput
              label="Data e hora da entrega do veículo"
              placeholder="Informe a data e hora da entrega do veículo"
              className="inputfield w-full"
              value={dataHoraEntregaVeiculo}
              onChange={(e) => setDataHoraEntregaVeiculo(e.target.value)} />
          </div>
        </div>
        <div className="p-dialog-buttons">
          <AppButton
            type="button"
            className="btn-acoes"
            icon="pi pi-check"
            label="Imprimir contrato"
            onClick={() => onSaveDataEntrega()}
          />
        </div>
      </Modal>
      <Modal
        header="Entrega de veículo"
        visible={visibleEntreguePara}
        onHide={() => setVisibleEntreguePara(false)}
        width="35"
        modal={true}
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <SearchInput
            //AutoComplete
              field="nomraz_pes"
              label={isVenda() ? 'Entregue para:' : 'Entregue por: '}
              value={entreguePara}
              placeholder="Nome do cliente"
              onSelect={async (e) => await onSelectPessoa(e)}
              onBlur={() => form.setFieldTouched('entreguePara.codigo_pes')}
              suggestions={sugestaoPessoas}
              completeMethod={sugerirPessoas}
              onChange={handleChangePessoa}
              className={'inputfield w-full'}
              itemTemplate={PessoaAutocompleteTemplate}
              //RegisterModal
              selected={form.values.entreguePara}
              onSaveModal={onSaveModalPessoa}
              onDeleteModal={async () => await onSelectPessoa({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.entreguePara.codigo_pes ? 'Pessoa Física - ' + form.values.entreguePara.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.entreguePara.codigo_pes ? 'Pessoa jurídica - ' + form.values.entreguePara.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}
              // FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              primarykeyname="codigo_pes"
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}
            />
          </div>
        </div>
        <div className="p-dialog-buttons">
          <AppButton
            type="button"
            className="btn-acoes"
            icon="pi pi-check"
            label={isVenda() ? 'Imprimir Termo de entrega' : 'Imprimir Termo de recebimento'}
            onClick={() => onSaveEntreguePara()}
          />
        </div>
      </Modal>
    </>
  )
}

export default ImpressoesNegModal
