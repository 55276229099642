import { transformAll } from '@overgear/yup-ast'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Container from '../components/layout/Container'
import Page from '../components/layout/Page'
import { baseForm } from '../components/utils/BaseForm'
import Confirm from '../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../components/utils/Message'
import CommonHelper from '../helpers/CommonHelper'
import BotoesHeaderForm from '../components/layout/BotoesHeaderForm'

export function Crud({
  match,
  onSaveModal,
  onDeleteModal,
  codigoSelecionado,
  onHide,
  service,
  modelGetDTO,
  primarykeyname,
  numeroKeyName,
  resource,
  formContent,
  formTitle,
  apenasVisualizacao,
  valida,
  mostrarBotoesHeader = true,
  isNewCrud = true,
}) {

  const [defaultValuesForm, setDefaultValuesForm] = useState(modelGetDTO() || null)
  const modelService = new service()
  const FormContent = formContent
  const id = match?.params?.id
  const [isNew, setIsNew] = useState(isNewCrud)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [editar, setEditar] = useState(false)
  const [actions, setActions] = useState(null)
  const [validator, setValidator] = useState({})
  const [camposObrigatorios, setCamposObrigatorios] = useState(null)
  const [header, setHeader] = useState(null)
  const [mostrarBotaoAcoes, setMostrarBotoesAcoes] = useState(!!mostrarBotoesHeader)
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [visibleCancelarModal, setVisibleCancelarModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const form = baseForm({
    initialValues: modelGetDTO(),
    validationSchema: validator
  })

  function habilitaButtonEditar() {
    if (edicaoHabilitada) {
      setEditar(true)
      setEdicaoHabilitada(false)
    } else {
      setEdicaoHabilitada(true)
      setEditar(false)
    }
  }

  async function getModel(id) {
    try {
      const model = modelGetDTO(await modelService.get(id))

      await form.setValues(model)
      setDefaultValuesForm(model)
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao obter informações')
    }
  }

  async function refreshForm() {
    await getModel(form.values.codigo_opv)
  }

  function getCamposObrigatoriosRecursively(fields, camposObrigatorios, parentFieldName) {
    for (const fieldName of Object.getOwnPropertyNames(fields)) {
      if (fields[fieldName].fields) {
        let newParentFieldName = fieldName

        if (parentFieldName) {
          newParentFieldName = `${parentFieldName}.${fieldName}`
        }

        getCamposObrigatoriosRecursively(fields[fieldName].fields, camposObrigatorios, newParentFieldName)
        continue
      }

      if (parentFieldName) {
        camposObrigatorios.push(`${parentFieldName}.${fieldName}`)
        continue
      }

      camposObrigatorios.push(fieldName)
    }
  }

  async function getFormValidator() {
    try {
      let formValidatorAST
      let formValidator
      if (valida) {
        setValidator(valida)
        formValidatorAST = valida
        formValidator = valida
      } else {
        setValidator(formValidator)
        formValidatorAST = await modelService.getFormValidator()
        formValidator = transformAll(formValidatorAST)
      }

      const camposObrigatorios = []
      getCamposObrigatoriosRecursively(formValidator.fields, camposObrigatorios)

      setCamposObrigatorios(camposObrigatorios)
      setLoading(false)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado!')
    }
  }

  useEffect(async () => {
    if (id || codigoSelecionado) {
      await getModel(id || codigoSelecionado)
      setEditar(true)
      setIsNew(false)
    } else {
      setDefaultValuesForm(modelGetDTO())
      setIsNew(true)
      setEdicaoHabilitada(true)
    }

    await getFormValidator()
  }, [id, codigoSelecionado])

  async function onSubmit(data) {
    try {
      const savedObject = await modelService.save(data)
      if (onSaveModal) {
        await onSaveModal(savedObject)
        onHide()
      } else {
        history.push(`/${history.location.pathname.split('/')[1]}/${savedObject[primarykeyname]}`)
      }

      setIsNew(false)
      setEdicaoHabilitada(false)
      setEditar(false)
      await getModel(savedObject[primarykeyname])

      showSuccessMessage('Registro salvo com sucesso!')

      return savedObject[primarykeyname]
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  async function onSave(data) {
    setEditar(false)
    form.handleSubmit()

    const errors = await form.validateForm(data)

    if (Object.keys(errors).length !== 0) {
      const errorMessage = typeof Object.values(errors)[0] !== 'string'
        ? Object.values(Object.values(errors)[0])[0]
        : Object.values(errors)[0]
      showWarnMessage(errorMessage || 'Campos obrigatórios não preenchidos!')
      return
    } else {
      return await onSubmit(data)
    }
  }

  async function onDelete() {
    try {
      await modelService.delete(form.values[primarykeyname])

      if (onDeleteModal) {
        onDeleteModal()
        onHide()
      } else {
        history.push(`/${resource}`)
      }

      showSuccessMessage('Registro deletado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar excluir o registro')
    }
  }


  return (
    <Page>
      {!loading && (
        <Container col="12">
          <div className={`flex form-header justify-content-${onHide ? 'end' : 'between'} ${(!CommonHelper.isDesktop() && actions) ? 'align-items-end' : ''}`}>
            {!onHide && (
              <>
                {header ? (<>{header}</>) : (<h1>{formTitle} {form.values[numeroKeyName || primarykeyname] ? `- ${form.values[numeroKeyName || primarykeyname]}` : ''}</h1>)}
              </>
            )}
            <BotoesHeaderForm
              isNew={isNew}
              isNewCrud={isNewCrud}
              apenasVisualizacao={apenasVisualizacao}
              mostrarBotaoAcoes={mostrarBotaoAcoes}
              actions={actions}
              editar={editar}
              habilitaButtonEditar={habilitaButtonEditar}
              onSave={onSave}
              form={form}
              setVisibleCancelarModal={setVisibleCancelarModal}
              setVisibleConfirmModal={setVisibleConfirmModal}
              edicaoHabilitada={edicaoHabilitada}
            />
          </div>
          <div className="grid pt-3">
            <FormContent
              form={form}
              edicaoHabilitada={edicaoHabilitada}
              setEdicaoHabilitada={(edicaoHabilitada) => setEdicaoHabilitada(edicaoHabilitada)}
              camposObrigatorios={camposObrigatorios}
              isNew={isNew}
              setActions={setActions}
              setEditar={setEditar}
              setHeader={setHeader}
              setFooter={() => { }}
              onSave={onSave}
              refreshForm={refreshForm}
              setMostrarBotoesAcoes={setMostrarBotoesAcoes}
            />
          </div>
        </Container>
      )}
      <Confirm
        visible={visibleConfirmModal}
        onConfirm={() => onDelete()}
        onCancel={() => setVisibleConfirmModal(false)}
        title="Confirmação"
        description="Deseja realmente excluir este registro?"
      />
      <Confirm
        visible={visibleCancelarModal}
        onConfirm={async () => {
          await form.setValues(defaultValuesForm)
          habilitaButtonEditar(!edicaoHabilitada)
          setVisibleCancelarModal(false)
        }}
        onCancel={() => setVisibleCancelarModal(false)}
        title="Atenção"
        description="Deseja cancelar as edições feitas?"
      />
    </Page>
  )
}
